import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Table, ActionLink, Label, Tag, Input, Fieldset, Radios, BackLink, Card, Details, Button, DateInput, InsetText, Textarea, ErrorSummary, Select, Checkboxes } from 'nhsuk-react-components';
import { listImplantHistories, createImplantHistory, updateImplantHistory, deleteImplantHistory, getImplantHistoryDetails } from '../../actions/implantHistoryActions';
// constants 
import { IMPLANT_HISTORY_CREATE_RESET, IMPLANT_HISTORY_UPDATE_RESET } from '../../constants/implantHistoryConstants';
import Pagination from '../../components/pagination/Pagination.component';
import AppSpinner from '../../components/spinner/AppSpinner.component';

import Callout from '../../components/callout/Callout.component';
import ISODateInput from '../../components/date/ISODateInput.component';
import QRCode from "react-qr-code";


export default function ImplantHistoryCRUDScreen({ history }) {

    // use react hook Dispatch to dispatch actions
    const dispatch = useDispatch();


    // reset the state of the implantHistoryCreate and implantHistoryUpdate
    useEffect(() => {
        dispatch({ type: IMPLANT_HISTORY_CREATE_RESET });
        dispatch({ type: IMPLANT_HISTORY_UPDATE_RESET });
    }, [dispatch]);

    // set initial filter to empty string, set default value 
    // {{ _.docker_api_url }}/api/v1/implantHistory/all?page=1&limit=2&filter=Email&query=gmail
    const [implantHistoryListFilter, setimplantHistoryListFilter] = useState({ query: '', page: 1, filter: 'patientName' });

    // get implantHistory list from redux store
    const implantHistoryList = useSelector((state) => state.implantHistoryList);
    const { loading, error, implantHistories, currentPage, totalPages } = implantHistoryList;

    // get implantHistory create from redux store
    const implantHistoryCreate = useSelector((state) => state.implantHistoryCreate);
    const { success: successCreate, error: implantHistoryCreateErrors } = implantHistoryCreate;

    // get implantHistory update from redux store
    const implantHistoryUpdate = useSelector((state) => state.implantHistoryUpdate);
    const { success: successUpdate, loading: implantHistoryUpdateProcessing } = implantHistoryUpdate;

    // get implantHistory delete from redux store
    const implantHistoryDelete = useSelector((state) => state.implantHistoryDelete);
    const { success: successDelete } = implantHistoryDelete;

    // get implantHistory details from redux store
    const implantHistoryDetails = useSelector((state) => state.implantHistoryDetails);
    const { implantHistory: implantHistoryDet, loading: implantHistoryDetailsLoading } = implantHistoryDetails;

    // newImplantHistory state
    const [newImplantHistory, setNewImplantHistory] = useState({});
    const [updatedImplantHistory, setUpdatedImplantHistory] = useState({});
    const [editMode, setEditMode] = useState(false);


    // listen to filter changes
    useEffect(() => {
        dispatch(listImplantHistories(implantHistoryListFilter));
    }, [dispatch, implantHistoryListFilter]);

    // handle page change
    const handlePageChange = (newPage) => {
        setimplantHistoryListFilter({ ...implantHistoryListFilter, page: newPage });
    };

    // handle search text field change
    const handleSearchChange = (e) => {
        setimplantHistoryListFilter({ ...implantHistoryListFilter, query: e.target.value });
    };

    // handle filter value change
    const handleFilterChange = (e) => {
        const selectedFilter = e.target.value;
        setimplantHistoryListFilter({ ...implantHistoryListFilter, filter: selectedFilter });
    };

    // get all implantHistories
    useEffect(() => {
        dispatch(listImplantHistories({}));
    }, [dispatch]);

    // listen to implantHistoryDet 
    useEffect(() => {
        if (implantHistoryDet) {
            // use spread operator to copy the implantHistoryDet object
            setUpdatedImplantHistory({ ...implantHistoryDet });
        }
    }, [implantHistoryDet]);

    // listen to editMode
    useEffect(() => {
        if (editMode === false) {
            // empty the newImplantHistory state
            setNewImplantHistory({
                patientName: '',
                patientNHSNumber: '',
                interventionCategory: '',
                eventDate: new Date().toISOString(),
                eventType: '',
                eventDetails: '',
                practitionerDetails: '',
                notes: ''
            });
        }
    }, [editMode]);


    // handle delete
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this implantHistory?')) {
            dispatch(deleteImplantHistory(id)).then(() => {
                dispatch(listImplantHistories({}));
            }
            );
        }
    };

    // handle edit
    const handleEditImplantHistory = (id) => {
        dispatch(getImplantHistoryDetails(id));
        setEditMode(true);
    };

    // handle create
    const handleCreateImplantHistory = () => {
        dispatch(createImplantHistory(newImplantHistory)).then(() => {
            setUpdatedImplantHistory(newImplantHistory);
            setEditMode(false);
            dispatch(listImplantHistories({}));
        }
        );
    };

    // handle update
    const handleUpdateImplantHistory = () => {
        dispatch(updateImplantHistory(updatedImplantHistory.id, updatedImplantHistory)).then(() => {

            setUpdatedImplantHistory(updatedImplantHistory);
            setEditMode(true);
            dispatch(getImplantHistoryDetails(updatedImplantHistory.id));
        }
        );
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <div className="welcome-box">
                                <div className="welcome-title d-flex justify-content-between align-items-center">
                                    <h2>Implant Alerts</h2>
                                </div>
                                <BackLink
                                    asElement="a"
                                    href="#"
                                    onClick={() => history.goBack()}
                                >
                                    Return back
                                </BackLink>
                                <Card>
                                    <Card.Content>
                                        <Card.Heading>Log Device Complications</Card.Heading>
                                        <Row gutter={[24, 24]}>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                <div className="nhsuk-card nhsuk-card--clickable">
                                                    <div className="nhsuk-card__content">
                                                        <QRCode
                                                            value="https://forms.office.com/e/JmQfDTPZSR"
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                                maxWidth: '200px',
                                                                display: 'block',
                                                                margin: '0 auto'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                                <p className="nhsuk-body">
                                                    We have implemented a streamlined process for reporting device complications, recently approved by the EP board. This form allows any MDT member to log complications via the QR code above or link below, taking <strong>less than a minute</strong> to complete.
                                                </p>

                                                <div className="nhsuk-u-margin-top-4">
                                                    <ActionLink
                                                        href="https://forms.office.com/e/JmQfDTPZSR"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Open Complications Form
                                                    </ActionLink>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Content>
                                </Card>

                            </div>

                        </Col>
                    </Row>
                    <h3>Search Implant Alerts</h3>
                    <Row>
                        <Col md="12">

                            {implantHistoryCreateErrors ?

                                <ErrorSummary
                                    aria-labelledby="error-summary-title"
                                    role="alert"
                                    tabIndex="-1"
                                >
                                    <ErrorSummary.Title id="error-summary-title">
                                        There is a problem
                                    </ErrorSummary.Title>
                                    <ErrorSummary.Body>
                                        <p>Check the following:</p>
                                        {implantHistoryCreateErrors ?
                                            <ul className="nhsuk-list nhsuk-list--bullet">
                                                {implantHistoryCreateErrors.map((error) => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </ul>
                                            : null

                                        }

                                    </ErrorSummary.Body>
                                </ErrorSummary>
                                : null}
                        </Col>
                    </Row>


                    <div className="nhsuk-grid-column-one-half">
                        <Card>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Input
                                        id="search-implantHistories"
                                        label="Search Records"
                                        name="search-implantHistories"
                                        type="search"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleSearchChange(e);
                                            }
                                        }
                                        }
                                    />
                                    <Select
                                        id="filter-implant-histories"
                                        label="Filter by"
                                        name="filter-implant-histories"
                                        onChange={handleFilterChange}
                                    >
                                        <Select.Option value="Patientname">Patient full name</Select.Option>
                                        <Select.Option value="PatientNHSNumber">Patient NHS Number</Select.Option>
                                    </Select>

                                </Card.Heading>
                                <Card.Description>
                                    <Fieldset>
                                        {implantHistories && implantHistories.length > 0 ? (
                                            implantHistories.map((implantHistory) => (
                                                <Details key={implantHistory.id}>
                                                    <Details.Summary>
                                                        {implantHistory.patientName}
                                                    </Details.Summary>

                                                    <Details.Text>
                                                        <p>
                                                            <strong>Patient Name:</strong> {implantHistory.patientName}
                                                        </p>
                                                        <p>
                                                            <strong>Patient NHS Number:</strong> {implantHistory.patientNHSNumber}
                                                        </p>

                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                disabled={implantHistoryDetailsLoading}
                                                                onClick={() =>
                                                                    handleEditImplantHistory(implantHistory.id)
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {" "}
                                                            <Button
                                                                secondary

                                                                onClick={() => handleDelete(implantHistory.id)}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </Details.Text>
                                                </Details>
                                            ))
                                        ) : (
                                            null
                                        )}

                                    </Fieldset>
                                    {loading ? (
                                        <AppSpinner />
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                        />

                                    )}

                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </div>
                    <div className="nhsuk-grid-column-one-half">
                        {editMode ? (
                            <Card id='edit-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Edit Alerts
                                        <InsetText>
                                            <p>
                                                This section allows you to edit an Alert. If you want to create a new Alert, please click the "Create Alert" button below.
                                            </p>
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                            >
                                                + Create Alert
                                            </Button>

                                        </InsetText>

                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="patientName"
                                            label="Patient Name"
                                            name="patientName"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, patientName: e.target.value })}
                                            value={updatedImplantHistory?.patientName}
                                            required
                                        />
                                        <Input
                                            id="patientNHSNumber"
                                            label="Patient NHS Number"
                                            name="patientNHSNumber"
                                            type="text"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, patientNHSNumber: e.target.value })}
                                            value={updatedImplantHistory?.patientNHSNumber}
                                            required
                                        />
                                        <Select
                                            id="interventionCategory"
                                            label="Intervention Category"
                                            name="interventionCategory"
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                setUpdatedImplantHistory({ ...updatedImplantHistory, interventionCategory: selectedValue });
                                            }}
                                            value={updatedImplantHistory?.interventionCategory}
                                            required
                                        >
                                            <Select.Option value="">Select an intervention category</Select.Option>
                                            <Select.Option value="No intervention (FU only of pt implanted elsewhere)">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                            <Select.Option value="First ever pacemaker/ICD system">First ever pacemaker/ICD system</Select.Option>
                                            <Select.Option value="Generator change only">Generator change only</Select.Option>
                                            <Select.Option value="New generator and lead(s) revision/replacement">New generator and lead(s) revision/replacement</Select.Option>
                                            <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>
                                            <Select.Option value="Downgrade - decreased functionality">Downgrade - decreased functionality</Select.Option>
                                            <Select.Option value="System explant">System explant</Select.Option>
                                            <Select.Option value="Wound revision/generator resite/haematoma">Wound revision/generator resite/haematoma</Select.Option>
                                            <Select.Option value="Lead intervention only">Lead intervention only</Select.Option>
                                            <Select.Option value="Monitor procedure only">Monitor procedure only</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>

                                        <Select
                                            id="eventType"
                                            label="Event Type"
                                            name="eventType"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventType: e.target.value })}
                                            value={updatedImplantHistory?.eventType}
                                            required
                                        >
                                            <Select.Option value="">Select an event type</Select.Option>
                                            <Select.Option value="Implant">Implant</Select.Option>
                                            <Select.Option value="Revision">Revision</Select.Option>
                                            <Select.Option value="Removal">Removal</Select.Option>
                                            <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                            <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                            <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                            <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                            <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                            <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                            <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>

                                        </Select>




                                        <ISODateInput
                                            id="updateEventDateTime"
                                            label="Event Date"
                                            name="eventDateTime"
                                            value={updatedImplantHistory?.eventDate}
                                            onChange={(eventDate) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventDate })}
                                            includeTime={true}
                                            required
                                        />

                                        <Textarea
                                            id="eventDetails"
                                            label="Event Details"
                                            name="eventDetails"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, eventDetails: e.target.value })}
                                            value={updatedImplantHistory?.eventDetails}
                                        />
                                        <Textarea
                                            id="PractitionerDetails"
                                            label="Practitioner Details"
                                            name="PractitionerDetails"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, practitionerDetails: e.target.value })}
                                            value={updatedImplantHistory?.practitionerDetails}
                                        />
                                        <Textarea
                                            id="Notes"
                                            label="Notes"
                                            name="Notes"
                                            onChange={(e) => setUpdatedImplantHistory({ ...updatedImplantHistory, notes: e.target.value })}
                                            value={updatedImplantHistory?.notes}
                                        />

                                        {(successCreate || successUpdate) ?
                                            <Callout
                                                label="Success"
                                                header="Success!"
                                                autoDismiss={true}
                                                dismissTime={5000}
                                                time={new Date().toLocaleTimeString()}
                                                title="Success!"
                                                message={successCreate ? "The implantHistory was created successfully." : "The implantHistory was updated successfully."}
                                                mode="success"
                                            />
                                            : null
                                        }

                                    </Card.Description>
                                    <Button
                                        disabled={implantHistoryUpdateProcessing}
                                        onClick={handleUpdateImplantHistory}
                                    >
                                        Save changes
                                    </Button>

                                </Card.Content>
                            </Card>
                        ) : (
                            <Card id='create-card'>
                                <Card.Content>
                                    <Card.Heading className="nhsuk-heading-m">
                                        Create a new Record
                                    </Card.Heading>
                                    <Card.Description>
                                        <Input
                                            id="create-patientName"
                                            label="Patient Name"
                                            name="createPatientName"
                                            type="text"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, patientName: e.target.value })}
                                            value={newImplantHistory?.patientName}
                                            required
                                        />
                                        <Input
                                            id="patientNHSNumber"
                                            label="Patient NHS Number"
                                            name="patientNHSNumber"
                                            type="text"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, patientNHSNumber: e.target.value })}
                                            value={newImplantHistory?.patientNHSNumber}
                                            required
                                        />
                                        <Select
                                            id="interventionCategory"
                                            label="Intervention Category"
                                            name="interventionCategory"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, interventionCategory: e.target.value })}
                                            value={newImplantHistory?.interventionCategory}
                                            required
                                        >
                                            <Select.Option value="">Select an intervention category</Select.Option>
                                            <Select.Option value="First ever pacemaker/ICD system">No intervention (FU only of pt implanted elsewhere)</Select.Option>
                                            <Select.Option value="Generator change only">First ever pacemaker/ICD system</Select.Option>
                                            <Select.Option value="New generator and lead(s) revision/replacement">Generator change only</Select.Option>
                                            <Select.Option value="Upgrade - increased functionality (to DDD, CRT, or ICD)">New generator and lead(s) revision/replacement</Select.Option>
                                            <Select.Option value="Downgrade - decreased functionality">Upgrade - increased functionality (to DDD, CRT, or ICD)</Select.Option>

                                            <Select.Option value="System explant">Downgrade - decreased functionality</Select.Option>
                                            <Select.Option value="Wound revision/generator resite/haematoma">System explant</Select.Option>
                                            <Select.Option value="Lead intervention only">Wound revision/generator resite/haematoma</Select.Option>
                                            <Select.Option value="Monitor procedure only">Lead intervention only</Select.Option>
                                            <Select.Option value="Other">Monitor procedure only</Select.Option>
                                        </Select>
                                        <Select
                                            id="eventType"
                                            label="Event Type"
                                            name="eventType"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, eventType: e.target.value })}
                                            value={newImplantHistory?.eventType}
                                            required
                                        >
                                            <Select.Option value="">Select an event type</Select.Option>
                                            <Select.Option value="Implant">Implant</Select.Option>
                                            <Select.Option value="Revision">Revision</Select.Option>
                                            <Select.Option value="Removal">Removal</Select.Option>
                                            <Select.Option value="Lead Extraction">Lead Extraction</Select.Option>
                                            <Select.Option value="Lead Implant">Lead Implant</Select.Option>
                                            <Select.Option value="Lead Revision">Lead Revision</Select.Option>
                                            <Select.Option value="Lead Removal">Lead Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Lead Replacement</Select.Option>
                                            <Select.Option value="Generator Implant">Generator Implant</Select.Option>
                                            <Select.Option value="Generator Revision">Generator Revision</Select.Option>
                                            <Select.Option value="Generator Removal">Generator Removal</Select.Option>
                                            <Select.Option value="Generator Replacement">Generator Replacement</Select.Option>
                                            <Select.Option value="Other">Other</Select.Option>
                                        </Select>


                                        <ISODateInput
                                            id="eventDateTime"
                                            label="Event Date"
                                            name="eventDateTime"
                                            value={newImplantHistory?.eventDate}
                                            onChange={(eventDate) => setNewImplantHistory({ ...newImplantHistory, eventDate })}
                                            includeTime={true}
                                            required
                                        />
                                        <Textarea
                                            id="eventDetails"
                                            label="Event Details"
                                            name="eventDetails"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, eventDetails: e.target.value })}
                                            value={newImplantHistory?.eventDetails}
                                        />
                                        <Textarea
                                            id="PractitionerDetails"
                                            label="Practitioner Details"
                                            name="PractitionerDetails"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, practitionerDetails: e.target.value })}
                                            valfue={newImplantHistory?.practitionerDetails}
                                        />
                                        <Textarea
                                            id="Notes"
                                            label="Notes"
                                            name="Notes"
                                            onChange={(e) => setNewImplantHistory({ ...newImplantHistory, notes: e.target.value })}
                                            value={newImplantHistory?.notes}
                                        />


                                    </Card.Description>

                                    <Button
                                        disabled={implantHistoryUpdateProcessing}
                                        onClick={handleCreateImplantHistory}
                                    >
                                        Create a Note
                                    </Button>

                                </Card.Content>
                            </Card>
                        )}

                    </div>

                </Container>
            </div>
        </React.Fragment>

    );
}






