import axios from 'axios';

import {
    CONSENT_LIST_REQUEST,
    CONSENT_LIST_SUCCESS,
    CONSENT_LIST_FAIL,

    CONSENT_DETAILS_REQUEST,
    CONSENT_DETAILS_SUCCESS,
    CONSENT_DETAILS_FAIL,

    CONSENT_DELETE_REQUEST,
    CONSENT_DELETE_SUCCESS,
    CONSENT_DELETE_FAIL,

    CONSENT_CREATE_REQUEST,
    CONSENT_CREATE_SUCCESS,
    CONSENT_CREATE_FAIL,

    CONSENT_UPDATE_REQUEST,
    CONSENT_UPDATE_SUCCESS,
    CONSENT_UPDATE_FAIL,

    CONSENT_REQUEST,
    CONSENT_FAIL,
    CONSENT_RESET,
    CONSENT_SUCCESS,

    CONSENT_COUNT_SUCCESS,
    CONSENT_COUNT_REQUEST,
    CONSENT_COUNT_FAIL,

    CONSENT_LIST_BYIDS_FAIL,
    CONSENT_LIST_BYIDS_REQUEST,
    CONSENT_LIST_BYIDS_SUCCESS,
    CONSENT_LIST_BYIDS_RESET,

    SET_CURRENT_PAGE,
    

} from "../constants/consentConstants";

// use dispatch to dispatch the request and throw it into the reducer
export const listConsents = (filter) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONSENT_LIST_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        let params = {};
        // get the filter
        if (filter) {
            params = filter;
        }

        const config = {
            headers: {
                'Content-type': 'application/json',
            },
            params
        }
        const { data } = await axios.get('/api/v1/consent/all', config);

        dispatch({
            type: CONSENT_LIST_SUCCESS,
            payload: data
        });


    } catch (error) {

        dispatch({
            type: CONSENT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occured, please try again later.",
        });
    }
}

export const listConsentsByIds = (ids) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONSENT_LIST_BYIDS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.post('/api/v1/consent/ids/all', ids, config);

        dispatch({
            type: CONSENT_LIST_BYIDS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: CONSENT_LIST_BYIDS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : "An error occurred, please try again later consents",
        });
    }
}

// count consents
export const countConsents = () => async (dispatch, getState) => {
    try {
      dispatch({ type: CONSENT_COUNT_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          'Content-type': 'application/json',
        },
      }
      const { data } = await axios.get('/api/v1/consent/count', config);
  
      dispatch({
        type: CONSENT_COUNT_SUCCESS,
        payload: data
      });
    } catch (error) {
      dispatch({
        type: CONSENT_COUNT_FAIL,
        payload: error.response && error.response.data.detail
            ? error.response.data.detail
            : "Failed to fetch consent counts",
      });
    }
  }
  


export const getConsentDetails = (_id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONSENT_DETAILS_REQUEST });
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }
        const { data } = await axios.get(`/api/v1/consent/${_id}`, config);

        dispatch({
            type: CONSENT_DETAILS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: CONSENT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
}

export const deleteConsent = (_id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONSENT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/v1/consent/${_id}/`,
            config
        )

        dispatch({
            type: CONSENT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: CONSENT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createConsent = (changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONSENT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const data = await axios.post(
            `/api/v1/consent/`,
            changes,
            config
        )

        dispatch({
            type: CONSENT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }        dispatch({
            type: CONSENT_CREATE_FAIL,
            payload: errorMessages,
        })
    }
}

const loadConsentBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
    });
};

// make consent using a local consent
export const makeConsent = (file, consentId, apiEndPoint, apiKey) => async (dispatch, getState) => {

    const consent = await loadConsentBase64(file);
    const consent_input = JSON.stringify({ "consent": consent });
    const {
        userLogin: { userInfo },
    } = getState()

    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`
        }
    }

    const data = await axios.post(
        apiEndPoint,
        consent_input,
        config
    )
    try {
        dispatch({
            type: CONSENT_RESET
        })
        await axios({
            method: "POST",
            url: apiEndPoint,

            data: JSON.stringify({ "data": consent }),
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => {
                dispatch({
                    type: CONSENT_SUCCESS
                });
                if (consentId && response.data) {
                    dispatch(updateConsent(consentId, { consent: response.data }));
                }
            })
            .catch(function (error) {
                dispatch({
                    type: CONSENT_FAIL
                });
                console.log(error.message);
            });
    }

    catch (error) {
        dispatch({
            type: CONSENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }

}


export const updateConsent = (_id, changes) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONSENT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const {data} = await axios.patch(
            `/api/v1/consent/${_id}/`,
            changes,
            config
        )

        dispatch({
            type: CONSENT_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: CONSENT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        let errorMessages = [];
        if (error.response && error.response.data) {
            // Check if 'errors' object exists
            if (error.response.data.errors) {
                // Loop through 'errors' object and construct error message
                for (const [key, value] of Object.entries(error.response.data.errors)) {
                    errorMessages.push(`${key}: ${value[0]} `);
                }
            }
            // Check if 'detail' field exists
            if (error.response.data.detail) {
                errorMessages.push(`Detail: ${error.response.data.detail} `);
            }
            // check if 'message' field exists
            if (error.response.data.message) {
                errorMessages.push(`Message: ${error.response.data.message} `);
            }
        } else {
            errorMessages.push(error.message);
        }

        dispatch({
            type: CONSENT_UPDATE_FAIL,
            // return a list of errors
            payload: errorMessages,
        })
    }
}