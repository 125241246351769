import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Link } from "react-router-dom";
import { WarningCallout } from 'nhsuk-react-components';
import NHSPatientLogin from "../components/nhsCis2Login/NHSPatientLogin.component";
import NHSMailLogin from "../components/nhsCis2Login/NHSMailLogin.component";
import MockNHSMailLogin from "../components/nhsCis2Login/MockNHSMailLogin.component";

function LoginScreen({ history, location }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetMessage, setResetMessage] = useState('');
    const [isPatient, setIsPatient] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const dispatch = useDispatch();

    const redirect = location.search ? location.search.split('=')[1] : '/';

    const userLogin = useSelector(state => state.userLogin);
    const { loading, success, error, userInfo } = userLogin;

    useEffect(() => {
        document.title = "Login";
    }, []);

    useEffect(() => {
        if (userInfo && success) {
            history.push(redirect ? redirect : '/');
        }
    }, [userInfo, success, history, redirect]);

    useEffect(() => {
        if (error) {
            setLoginError(error);
        }
    }, [error]);

    const submitHandler = (e) => {
        e.preventDefault();
        setLoginError(null);
        dispatch(login(email, password));
    };

    const handleLoginSuccess = (userInfo) => {
        history.push('/dashboard');
    };

    const toggleUserType = () => {
        setIsPatient(!isPatient);
        setLoginError(null);
    };

    return (
        <React.Fragment>
            <div className="nhsuk-width-container">
                <main className="nhsuk-main-wrapper" id="maincontent" role="main">
                    <div className="nhsuk-grid-row">
                        <div className="nhsuk-grid-column-two-thirds">
                            <h1>{isPatient ? 'Patient Authentication' : 'Staff Authentication'}</h1>

                            <h3>
                                <a href="#" onClick={toggleUserType}>
                                    ➤ I am a {isPatient ? 'staff member' : 'patient'}
                                </a>
                            </h3>

                            {loginError && (
                                <div className="nhsuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabIndex="-1">
                                    <h3 className="nhsuk-error-summary__title" id="error-summary-title">
                                        There is a problem
                                    </h3>
                                    <div className="nhsuk-error-summary__body">
                                        <ul className="nhsuk-list nhsuk-error-summary__list" role="list">
                                            <li>{loginError}</li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                            <h2>Enter your login details</h2>

                            <form onSubmit={submitHandler} className="nhsuk-form-group">
                                <label className="nhsuk-label" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="email"
                                    name="email"
                                    type="text"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                                <div className="nhsuk-u-padding-bottom-5"></div>

                                <label className="nhsuk-label" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    className="nhsuk-input"
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />

                                <div className="nhsuk-u-padding-bottom-5"></div>

                                <div className="nhsuk-grid-row">
                                    <div className="nhsuk-width-container">
                                        <button className="nhsuk-button" data-module="nhsuk-button" type="submit" style={{ width: '100%' }} disabled={loading}>
                                            {loading ? 'Authenticating ...' : 'Login'}
                                        </button>
                                    </div>
                                </div>
                            </form>

                            <div className="nhsuk-grid-row">
                                <div className="nhsuk-width-container">
                                    {isPatient ? (
                                        <WarningCallout label="Warning callout">
                                            <WarningCallout.Label>Important information</WarningCallout.Label>
                                            <p>If you can't login to your account, please contact your administrator.</p>
                                        </WarningCallout>
                                    ) : (
                                        <NHSMailLogin onLoginSuccess={handleLoginSuccess} />
                                    )}
                                </div>
                            </div>

                            <p>Don't have an account? <Link to={redirect ? `/register?redirect=${redirect}` : "/register"} className="text-primary fw-semibold">Register</Link></p>

                            <Link to={redirect ? `/resetpasswordsetup?redirect=${redirect}` : "/resetpasswordsetup"} className="text-primary fw-semibold">
                                Forgotten password?
                            </Link>

                            {resetMessage && (
                                <WarningCallout>
                                    <p>{resetMessage}</p>
                                </WarningCallout>
                            )}

                            <p>By using this service you are agreeing to our <a href="#">terms of use</a> and <a href="#">privacy policy</a>.</p>
                        </div>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
}

export default LoginScreen;