import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Details, SummaryList, Button } from "nhsuk-react-components";
import Modal from '../modal/Modal.component';
import { debounce } from 'lodash';

const EntitySelect = ({
  singularEntity,
  pluralEntity,
  constantPrefix,
  listAction,
  listByIdsAction,
  selectedIds = [],
  onChange,
  nameProperty = 'name',
  idProperty = 'id',
  summaryKeys = [],
  title
}) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const entityList = useSelector((state) => state[`${singularEntity}List`]) || {};
  const { loading = false, [pluralEntity]: entities = [], error } = entityList;

  const entityListByIDs = useSelector((state) => state[`${singularEntity}ListByIDs`]) || {};
  const { loading: loadingSelected = false, [pluralEntity]: selectedEntities = [], error: errorSelected } = entityListByIDs;

  const isInitialMount = useRef(true);

  // Load entities by IDs only when the component mounts
  useEffect(() => {
    if (selectedIds) {
      dispatch(listByIdsAction(selectedIds));
    } 
  }, [dispatch, listByIdsAction, selectedIds]);

  // Call onChange only when selectedEntities are loaded for the first time
  useEffect(() => {
    if (isInitialMount.current && selectedEntities.length > 0) {
      isInitialMount.current = false;
      onChange(selectedEntities);
    }
  }, [selectedEntities, onChange]);

  // Load the entities based on filter for search
  useEffect(() => {
    dispatch(listAction({ filter }));
  }, [dispatch, listAction, filter]);

  const handleChange = (selectedOptions) => {
    const newSelectedEntities = selectedOptions ? selectedOptions.map(option =>
      entities.find(e => e[idProperty] === option.value)
    ).filter(Boolean) : [];

    onChange(newSelectedEntities);
  };


  const handleSearchChange = debounce((value) => {
    if (value.length >= 2) {
      setFilter(value);
    }
  }, 300);

  const handleEntityClick = (entity, event) => {
    event.preventDefault();
    setSelectedEntity(entity);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedEntity(null);
    setModalOpen(false);
  };

  const options = entities.map(entity => ({
    value: entity[idProperty],
    label: entity[nameProperty]
  }));

  const value = selectedEntities.map(entity => ({
    value: entity[idProperty],
    label: entity[nameProperty]
  }));

  return (
    <React.Fragment>
      <Details style={{ marginTop: '1rem' }}>
        <Details.Summary>{title || singularEntity}</Details.Summary>
        <Details.Text>
          <ul>
            {selectedEntities.map(entity => (
              <li key={entity[idProperty]} id={`${singularEntity}-${entity[idProperty]}`}>
                <a
                  href={`#${singularEntity}-${entity[idProperty]}`}
                  onClick={(event) => handleEntityClick(entity, event)}
                >
                  {entity[nameProperty]}
                </a>
              </li>
            ))}
          </ul>
        </Details.Text>
      </Details>
      <Select
        isMulti
        isSearchable

        options={options}
        value={value}
        onChange={handleChange}
        onInputChange={handleSearchChange}
        isLoading={loading || loadingSelected}
      />
      <Modal show={modalOpen} handleClose={closeModal}>
        {selectedEntity && (
          <>
            <h2>{selectedEntity[nameProperty]}</h2>
            <SummaryList>
              {Object.entries(selectedEntity)
                .filter(([key]) => summaryKeys.includes(key))
                .map(([key, value]) => (
                  <SummaryList.Row key={key}>
                    <SummaryList.Key>
                      {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                    </SummaryList.Key>
                    <SummaryList.Value>
                      {value ? value.toString() : "-"}
                    </SummaryList.Value>
                  </SummaryList.Row>
                ))
              }
            </SummaryList>
            <Button onClick={closeModal}>Close</Button>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default EntitySelect;
