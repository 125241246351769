import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Details, Tag, Form, Textarea, ActionLink, Button } from 'nhsuk-react-components';
import { Timeline, Skeleton } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { listImplantHistoriesByPatientNhsNumber, createImplantHistory } from '../../actions/implantHistoryActions';
import Modal from '../modal/Modal.component';
import ISODateInput from '../date/ISODateInput.component';
import { formatISODate } from '../../utils/functions';
import Callout from '../callout/Callout.component';

const ReadOnlyClinicalAlerts = ({ patientNHSNumber, patientName, limit = 3, showOnlyPatientVisible = true }) => {
    const dispatch = useDispatch();
    const [showReportModal, setShowReportModal] = useState(false);
    const [showAllRecords, setShowAllRecords] = useState(false);
    const [reportFormData, setReportFormData] = useState({
        eventDetails: '',
        notes: '',
        eventDate: new Date().toISOString(),
    });
    const [formError, setFormError] = useState(null);

    const [alertsFilter, setAlertsFilter] = useState({
        query: patientNHSNumber,
        page: 1,
        sortBy: 'EventDate',
        filter: 'patientNHSNumber',
        order: 'DESC',
        limit: showAllRecords ? 100 : limit
    });

    const implantHistoryListByNhsNumber = useSelector((state) => state.implantHistoryListByNhsNumber);
    const {
        implantHistories = [],
        loading: loadingAlerts,
        totalPages: alertsTotalPages
    } = implantHistoryListByNhsNumber;

    const implantHistoryCreate = useSelector((state) => state.implantHistoryCreate);
    const { loading: createLoading, success: createSuccess, error: createError } = implantHistoryCreate;

    useEffect(() => {
        if (patientNHSNumber) {
            dispatch(listImplantHistoriesByPatientNhsNumber(patientNHSNumber, alertsFilter));
        }
    }, [dispatch, patientNHSNumber, alertsFilter]);

    useEffect(() => {
        if (createSuccess) {
            setShowReportModal(false);
            setReportFormData({
                eventDetails: '',
                notes: '',
                eventDate: new Date().toISOString(),
            });
            setAlertsFilter(prev => ({ ...prev, page: 1 }));
        }
    }, [createSuccess]);

    const handleSubmitReport = (e) => {
        e.preventDefault();
        if (!reportFormData.eventDetails.trim()) {
            setFormError('Please provide details about the issue');
            return;
        }

        const reportData = {
            patientName: patientName,
            patientNHSNumber: patientNHSNumber,
            eventType: 'Patient Report',
            eventDate: reportFormData.eventDate,
            eventDetails: reportFormData.eventDetails,
            notes: reportFormData.notes,
            isPatientVisible: true,
            interventionCategory: 'Patient Report',
            practitionerDetails: 'Patient Self-Report',
        };

        dispatch(createImplantHistory(reportData));
    };

    const handleShowMore = () => {
        setShowAllRecords(true);
        setAlertsFilter(prev => ({ ...prev, limit: 100 }));
    };

    const handleShowLess = () => {
        setShowAllRecords(false);
        setAlertsFilter(prev => ({ ...prev, limit }));
    };

    const getEventTypeColor = (eventType) => {
        if (eventType.includes('Implant')) return '#218838';
        if (eventType.includes('Revision')) return '#ffc107';
        if (eventType.includes('Removal') || eventType.includes('Extraction')) return '#c82333';
        return '#17a2b8';
    };

    const filteredHistories = implantHistories
        ? showOnlyPatientVisible
            ? implantHistories.filter(history => history.isPatientVisible)
            : implantHistories
        : [];

    return (
        <div>
            <div style={{ margin: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ fontSize: '1.4rem', marginBottom: '0.5rem' }}>Device Alerts & Reports</h3>
                    <button
                        onClick={() => setShowReportModal(true)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            padding: '0.5rem 1rem',
                            border: 'none',
                            borderRadius: '24px',
                            backgroundColor: '#fff',
                            color: '#ff4d4f',
                            boxShadow: '0 2px 4px rgba(255, 77, 79, 0.2)',
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            fontSize: '0.9rem',
                            fontWeight: '500',
                            ':hover': {
                                backgroundColor: '#fff1f0',
                                transform: 'translateY(-1px)',
                                boxShadow: '0 4px 6px rgba(255, 77, 79, 0.25)',
                            }
                        }}
                    >
                        <WarningFilled style={{ fontSize: '1.1rem', color: '#ff4d4f' }} />
                        Report an Issue
                    </button>
                </div>
            </div>            <div className="nhsuk-u-padding-bottom-3" style={{
                maxHeight: showAllRecords ? 'none' : '600px',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#005eb8 #f0f4f5'
            }}>
                <style>{`
                    .nhsuk-u-padding-bottom-3::-webkit-scrollbar {
                        width: 6px;
                    }
                    .nhsuk-u-padding-bottom-3::-webkit-scrollbar-track {
                        background: #f0f4f5;
                    }
                    .nhsuk-u-padding-bottom-3::-webkit-scrollbar-thumb {
                        background: #005eb8;
                        border-radius: 3px;
                    }
                    .nhsuk-u-padding-bottom-3::-webkit-scrollbar-thumb:hover {
                        background: #003d78;
                    }
                `}</style>

                {loadingAlerts ? (
                    <Skeleton active paragraph={{ rows: 2 }} />
                ) : filteredHistories.length > 0 ? (
                    <>
                        <Timeline>
                            {filteredHistories.map((alert) => (
                                <Timeline.Item
                                    key={alert.id}
                                    dot={<div style={{
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%',
                                        backgroundColor: getEventTypeColor(alert.eventType),
                                        border: `2px solid ${getEventTypeColor(alert.eventType)}`
                                    }} />}
                                    color={getEventTypeColor(alert.eventType)}
                                >
                                    <div style={{
                                        backgroundColor: '#ffffff',
                                        padding: '1rem',
                                        borderRadius: '8px',
                                        boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
                                        marginBottom: '1.5rem'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start',
                                            marginBottom: '0.5rem'
                                        }}>
                                            <div>
                                                <div style={{
                                                    fontSize: '0.875rem',
                                                    color: '#666',
                                                    marginBottom: '0.25rem'
                                                }}>
                                                    {formatISODate(alert.eventDate, true)}
                                                </div>
                                                <div style={{
                                                    fontSize: '1.125rem',
                                                    fontWeight: '600',
                                                    color: '#222'
                                                }}>
                                                    {alert.interventionCategory}
                                                </div>
                                            </div>
                                            <Tag style={{
                                                backgroundColor: `${getEventTypeColor(alert.eventType)}15`,
                                                color: getEventTypeColor(alert.eventType),
                                                border: 'none',
                                                padding: '4px 12px',
                                                borderRadius: '16px'
                                            }}>
                                                {alert.eventType}
                                            </Tag>
                                        </div>

                                        {alert.eventDetails && (
                                            <div style={{ marginBottom: '0.75rem' }}>
                                                <div style={{ fontSize: '0.875rem', color: '#666' }}>Details</div>
                                                <div style={{ color: '#333' }}>{alert.eventDetails}</div>
                                            </div>
                                        )}

                                        {alert.notes && (
                                            <div style={{ marginBottom: '0.75rem' }}>
                                                <div style={{ fontSize: '0.875rem', color: '#666' }}>Notes</div>
                                                <div style={{ color: '#333' }}>{alert.notes}</div>
                                            </div>
                                        )}

                                        {alert.practitionerDetails && (
                                            <div style={{
                                                marginTop: '0.75rem',
                                                padding: '0.5rem',
                                                backgroundColor: '#f8f9fa',
                                                borderRadius: '4px',
                                                fontSize: '0.875rem'
                                            }}>
                                                <span style={{ color: '#666' }}>Practitioner: </span>
                                                <span style={{ color: '#333', fontWeight: '500' }}>
                                                    {alert.practitionerDetails}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                        {filteredHistories.length >= limit && !showAllRecords && (
                            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                <Button onClick={handleShowMore} secondary>
                                    View All Records
                                </Button>
                            </div>
                        )}
                        {showAllRecords && filteredHistories.length > limit && (
                            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                                <Button onClick={handleShowLess} secondary>
                                    Show Less
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    <p>No device alerts or reports are currently available.</p>
                )}
            </div>

            <Modal
                show={showReportModal}
                handleClose={() => setShowReportModal(false)}
                blur={true}
                theme="light"
            >
                <h2 style={{ marginBottom: '1.5rem' }}>Report a Device-Related Issue</h2>

                <Callout
                    mode='warning'
                    header="Important Information"
                    message={
                        <>
                            <p>Please note that any information you provide here will be visible to your healthcare provider and may be used to inform your care. Please do not include any sensitive personal information in this form.
                            <strong>Response Time Notice:</strong> Due to a high volume of reports, the standard review time is 7–14 days. All submissions are thoroughly reviewed and monitored by the Heart Rhythm Management Team at Barts Heart Centre.
                            <strong>For Urgent Issues:</strong> If you have an urgent or emergency device-related concern, please contact the Device Clinic directly for immediate assistance.
                            </p>
                        </>
                    }
                />
                <Form onSubmit={handleSubmitReport}>
                    {formError && (
                        <div className="nhsuk-error-message">
                            <span role="alert">{formError}</span>
                        </div>
                    )}

                    {createError && (
                        <div className="nhsuk-error-message">
                            <span role="alert">
                                {typeof createError === 'string' ? createError : 'An error occurred while submitting your report'}
                            </span>
                        </div>
                    )}

                    <ISODateInput
                        id="eventDate"
                        label="When did you notice this issue?"
                        hint="Select the date when you first noticed the issue"
                        name="eventDate"
                        includeTime={true}
                        value={reportFormData.eventDate}
                        onChange={(date) => setReportFormData(prev => ({
                            ...prev,
                            eventDate: date
                        }))}
                        required
                    />

                    <Textarea
                        id="eventDetails"
                        label="What issues are you experiencing?"
                        hint="Please provide details about any problems or concerns with your device"
                        name="eventDetails"
                        error={formError && !reportFormData.eventDetails.trim() ? 'Please provide details about the issue' : ''}
                        onChange={(e) => {
                            setFormError(null);
                            setReportFormData(prev => ({
                                ...prev,
                                eventDetails: e.target.value
                            }));
                        }}
                        value={reportFormData.eventDetails}
                        required
                    />

                    <Textarea
                        id="notes"
                        label="Additional Notes (Optional)"
                        hint="Any other information you'd like to share"
                        name="notes"
                        onChange={(e) => setReportFormData(prev => ({
                            ...prev,
                            notes: e.target.value
                        }))}
                        value={reportFormData.notes}
                    />

                    <div style={{ marginTop: '2rem', display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                        <Button
                            secondary
                            onClick={() => setShowReportModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmitReport}
                            disabled={createLoading}
                        >
                            {createLoading ? 'Submitting...' : 'Submit Report'}
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default ReadOnlyClinicalAlerts;